import '@dynamic-labs/sdk-api';
import { logger } from '../../../shared/logger.js';
import '@dynamic-labs/iconic';
import '@dynamic-labs/wallet-connector-core';
import 'react/jsx-runtime';
import '../../../context/ViewContext/ViewContext.js';
import 'react';
import '@dynamic-labs/wallet-book';
import '@dynamic-labs/utils';
import '../../../utils/constants/colors.js';
import '../../../shared/utils/classes/storage/localStorage.js';
import 'viem';
import '../../../shared/consts/index.js';

const errorCodesMapping = {
    '-32603': 'Could not replace existing transaction',
    0: 'The operation either timed out or was not allowed.',
    16: 'Invalid Passkey signature. Select the passkey for this account and device.',
    INSUFFICIENT_FUNDS: 'Insufficient funds for this transaction.',
};
const errorReasonsMapping = {
    'provided ENS name resolves to null': 'Invalid address. Please check that the entered address is correct.',
};
const transactionErrorMessage = (error) => {
    var _a;
    logger.debug('transaction error]:', error);
    const { code } = error;
    const defaultMessage = 'Something went wrong.';
    // turnkey request errors
    if (((_a = error === null || error === void 0 ? void 0 : error.cause) === null || _a === void 0 ? void 0 : _a.code) !== undefined &&
        errorCodesMapping[error.cause.code] !== undefined) {
        return errorCodesMapping[error.cause.code];
    }
    if (!code)
        return defaultMessage;
    return (errorCodesMapping[code] ||
        (error.reason && errorReasonsMapping[error.reason]) ||
        defaultMessage);
};

export { transactionErrorMessage };
