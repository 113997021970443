import { useRef, useEffect } from 'react';
import { useIsomorphicLayoutEffect } from '../useIsomorphicLayoutEffect/useIsomorphicLayoutEffect.js';

const useWalletConnectorEvent = (eventEmitters, eventName, handler) => {
    const callbackRef = useRef(handler);
    useIsomorphicLayoutEffect(() => {
        callbackRef.current = handler;
    }, [handler]);
    useEffect(() => {
        if (!eventEmitters)
            return;
        const eventEmittersArray = Array.isArray(eventEmitters)
            ? eventEmitters
            : [eventEmitters];
        /**
         * Initialize the event listeners for the wallet connectors
         * to ensure they were initialized before listening to events
         */
        eventEmittersArray.forEach((connector) => connector.initEventListener());
        const uniqueEventEmitters = Array.from(new Set(eventEmittersArray));
        const unsubscribeCallbacks = uniqueEventEmitters.map((emitter) => {
            const callback = (...args) => {
                callbackRef.current(emitter, ...args);
            };
            emitter.on(eventName, callback);
            return () => emitter.off(eventName, callback);
        });
        return () => unsubscribeCallbacks.forEach((unsubscribe) => unsubscribe());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventEmitters, eventName]);
};

export { useWalletConnectorEvent };
