import { useInternalDynamicContext } from '../../context/DynamicContext/useInternalDynamicContext.js';
import { useViewContext } from '../../context/ViewContext/ViewContext.js';

const useNoAccessText = () => {
    const { socialMediaLinkText, socialMediaUrl, accessDeniedMessagePrimary, accessDeniedMessageSecondary, } = useInternalDynamicContext();
    const { view } = useViewContext();
    const isChainalysisView = view === 'chainalysis-blocked-wallet';
    const isGateBlockedView = view === 'gate-blocked-wallet';
    const isAccessListView = view === 'no-access';
    const getSocialMediaLinkText = () => {
        if (isChainalysisView) {
            return textData.chainalysis._socialMediaLinkText;
        }
        if (isGateBlockedView) {
            return textData.gate._socialMediaLinkText;
        }
        return socialMediaLinkText;
    };
    const getSocialMediaUrl = () => {
        if (isChainalysisView) {
            return textData.chainalysis._socialMediaUrl;
        }
        if (isGateBlockedView) {
            return textData.gate._socialMediaUrl;
        }
        return socialMediaUrl;
    };
    const getTitle = () => {
        if (isChainalysisView) {
            return textData.chainalysis.title;
        }
        if (isGateBlockedView) {
            return textData.gate.title;
        }
        return accessDeniedMessagePrimary || textData.default.title;
    };
    const getSubtitle = () => {
        if (isChainalysisView) {
            return textData.chainalysis.subtitle;
        }
        if (isGateBlockedView) {
            return textData.gate.subtitle;
        }
        return accessDeniedMessageSecondary || textData.default.subtitle;
    };
    const getButtonTitle = () => {
        if (isChainalysisView) {
            return textData.chainalysis.buttonTitle;
        }
        if (isGateBlockedView) {
            return textData.gate.buttonTitle;
        }
        return textData.default.buttonTitle;
    };
    return {
        _socialMediaLinkText: getSocialMediaLinkText(),
        _socialMediaUrl: getSocialMediaUrl(),
        buttonTitle: getButtonTitle(),
        isAccessListView,
        isChainalysisView,
        isGateBlockedView,
        subtitle: getSubtitle(),
        title: getTitle(),
    };
};
const textData = {
    chainalysis: {
        _socialMediaLinkText: 'Why am I seeing this message?',
        _socialMediaUrl: 'https://docs.dynamic.xyz/docs',
        buttonTitle: 'Try another method',
        subtitle: 'This wallet has been correlated to illicit activity and cannot access this site.',
        title: 'This address seems corrupted.',
    },
    default: {
        buttonTitle: 'Try another method',
        subtitle: "We couldn't find your wallet address or email on our allow list of customers.",
        title: 'Access denied',
    },
    gate: {
        _socialMediaLinkText: undefined,
        _socialMediaUrl: undefined,
        buttonTitle: 'Try a different wallet',
        subtitle: 'A NFT or a token is required to access this site.',
        title: 'You cannot access the site',
    },
};

export { textData, useNoAccessText };
