const errorMap = {
    0: 'The operation either timed out or was not allowed.',
    16: 'Invalid Passkey signature. Select the passkey for this account and device.',
};
const getProperErrorMessage = (signMessageError) => {
    var _a;
    let error = signMessageError.reason || 'Something went wrong';
    // turnkey request errors
    if (((_a = signMessageError === null || signMessageError === void 0 ? void 0 : signMessageError.cause) === null || _a === void 0 ? void 0 : _a.code) !== undefined &&
        errorMap[signMessageError.cause.code] !== undefined) {
        error = errorMap[signMessageError.cause.code];
    }
    return error;
};

export { getProperErrorMessage };
