import { jsx } from 'react/jsx-runtime';
import { DynamicWidgetDropdown } from './variants/DynamicWidgetDropdown/DynamicWidgetDropdown.js';
import { DynamicWidgetModal } from './variants/DynamicWidgetModal/DynamicWidgetModal.js';

const mapDynamicWidgetVariantToComponent = {
    dropdown: DynamicWidgetDropdown,
    modal: DynamicWidgetModal,
};
const DynamicWidgetVariants = ({ zIndex, variant, children, isOpen, setIsOpen, transitionEvents }) => {
    const DynamicVariantComponent = mapDynamicWidgetVariantToComponent[variant || 'modal'];
    return (jsx(DynamicVariantComponent, { transitionEvents: transitionEvents, zIndex: zIndex, isShown: isOpen, onClose: () => setIsOpen(false), children: children }));
};

export { DynamicWidgetVariants };
