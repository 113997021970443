import { __awaiter } from '../../_virtual/_tslib.js';
import { isMobile, isSamsungBrowser } from '@dynamic-labs/utils';
import LegacyInjectedWalletBase from './LegacyInjectedWalletBase.js';

class PhantomEvm extends LegacyInjectedWalletBase {
    constructor() {
        super(...arguments);
        this.name = 'PhantomEvm';
    }
    fetchPublicAddress() {
        const _super = Object.create(null, {
            fetchPublicAddress: { get: () => super.fetchPublicAddress }
        });
        return __awaiter(this, void 0, void 0, function* () {
            if (this.isInstalledOnBrowser()) {
                return _super.fetchPublicAddress.call(this);
            }
            const url = encodeURIComponent(window.location.toString());
            const ref = encodeURIComponent(window.location.origin);
            // samsung browser only supports native links, not universal links
            if (isMobile()) {
                if (isSamsungBrowser()) {
                    window.location.assign(`phantom://browse/${url}?ref=${ref}`);
                }
                else {
                    window.location.assign(`https://phantom.app/ul/browse/${url}?ref=${ref}`);
                }
            }
            return undefined;
        });
    }
}

export { PhantomEvm };
