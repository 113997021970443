import { __awaiter } from '../../../../../../_virtual/_tslib.js';
import { isEmailWalletConnector, isEmailOTPWalletConnector, isBloctoConnector } from '@dynamic-labs/wallet-connector-core';
import { showPendingConnectView } from '../helpers/showPendingConnectView.js';
import { isConnectOnly } from '../helpers/isConnectOnly.js';
import { useInternalDynamicContext } from '../../../../context/DynamicContext/useInternalDynamicContext.js';
import { useViewContext } from '../../../../context/ViewContext/ViewContext.js';
import { useErrorContext } from '../../../../context/ErrorContext/ErrorContext.js';
import '@dynamic-labs/sdk-api';
import { logger } from '../../../../shared/logger.js';
import '@dynamic-labs/iconic';
import 'react/jsx-runtime';
import 'react';
import '@dynamic-labs/wallet-book';
import '@dynamic-labs/utils';
import { LAST_USED_WALLET } from '../../../constants/localStorage.js';
import '../../../constants/colors.js';
import { LocalStorage } from '../../../../shared/utils/classes/storage/localStorage.js';
import 'viem';
import '../../../../shared/consts/index.js';
import { useVerifyWallet } from '../../useVerifyWallet/useVerifyWallet.js';
import { useCaptchaContext } from '../../../../context/CaptchaContext/CaptchaContext.js';
import { useHandleWalletsToConnect } from '../../useHandleWalletsToConnect/useHandleWalletsToConnect.js';
import '@dynamic-labs/multi-wallet';
import { isSupportedNetwork } from '../../../functions/isSupportedNetwork/isSupportedNetwork.js';
import { isProgrammableNetworkSwitchSupported } from '../../../functions/isProgrammableNetworkSwitchSupported/isProgrammableNetworkSwitchSupported.js';

const useConnectAndSign = ({ shouldUpdateWallets = true, shouldCallCallback = true, } = {}) => {
    const { setView, goToInitialView } = useViewContext();
    const { setErrorMessage } = useErrorContext();
    const { captchaToken } = useCaptchaContext();
    const { authMode, connectWallet, consumeNonce, displaySiweStatement, environmentId, projectSettings, setIsSingleWalletAccount, user, walletUiUtils, setCallback, setIsVerificationInProgress, } = useInternalDynamicContext();
    const { handleWalletsToConnect } = useHandleWalletsToConnect();
    const verifyWallet = useVerifyWallet({
        consumeNonce,
        displaySiweStatement,
        environmentId,
        projectSettings,
        setIsSingleWalletAccount,
    });
    return ({ walletConnector, fetchPublicAddressOpts = undefined, }) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b;
        // Raise verification ongoing flag
        setIsVerificationInProgress(true);
        try {
            yield showPendingConnectView(walletConnector, setView);
            if (isEmailWalletConnector(walletConnector)) {
                if (isEmailOTPWalletConnector(walletConnector)) {
                    setView('email-wallet-otp-verification-view');
                }
                else if (!isBloctoConnector(walletConnector)) {
                    setView('wait-for-email-confirmation-view');
                }
            }
            const publicWalletAddress = yield connectWallet(walletConnector, fetchPublicAddressOpts, shouldCallCallback);
            if (!publicWalletAddress) {
                return;
            }
            const walletNetwork = yield walletConnector.getNetwork();
            const usesSupportedNetwork = isSupportedNetwork({
                network: walletNetwork,
                walletConnector,
            });
            if (!usesSupportedNetwork) {
                if (isProgrammableNetworkSwitchSupported(walletConnector)) {
                    return setView('network-not-supported');
                }
                return setView('network-not-supported-manual');
            }
            setCallback('connectSuccess');
            if (isConnectOnly(user, authMode)) {
                LocalStorage.setToLS(LAST_USED_WALLET, walletConnector.key);
                if (shouldUpdateWallets) {
                    handleWalletsToConnect({ walletConnector });
                }
                return;
            }
            if ((_a = projectSettings === null || projectSettings === void 0 ? void 0 : projectSettings.security.hCaptcha) === null || _a === void 0 ? void 0 : _a.enabled) {
                setView('captcha');
                return;
            }
            if (!isEmailWalletConnector(walletConnector) ||
                isBloctoConnector(walletConnector)) {
                setView('pending-signature');
            }
            else {
                walletUiUtils.disabledConfirmationOnce();
            }
            yield verifyWallet({
                captchaToken,
                publicWalletAddress,
                walletConnector,
            });
        }
        catch (err) {
            const userCancelled = (err === null || err === void 0 ? void 0 : err.code) === 'user-cancelled';
            if (!userCancelled)
                logger.debug(err);
            setErrorMessage((_b = err === null || err === void 0 ? void 0 : err.code) === null || _b === void 0 ? void 0 : _b.toString());
            goToInitialView();
            // Since auth failed, clear verification flag
            // But if user cancelled, clearStatesOnBackClick will have already cleared it. So avoid
            // clearing again since that would trigger a warning
            if (!userCancelled)
                setIsVerificationInProgress(false);
            return;
        }
    });
};

export { useConnectAndSign };
