import { useMemo } from 'react';

const useTransactionTotal = ({ transactionValue, gasTotalPrice, }) => useMemo(() => {
    if (gasTotalPrice !== undefined && transactionValue !== undefined) {
        return gasTotalPrice + transactionValue;
    }
    return transactionValue || gasTotalPrice;
}, [gasTotalPrice, transactionValue]);

export { useTransactionTotal };
