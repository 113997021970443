import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import sdkTranslation from './en/translation.js';

class Locale {
    static setResources(customResources) {
        if (Locale.__resources__) {
            return;
        }
        if (!customResources) {
            Locale.__resources__ = {
                en: { translation: sdkTranslation },
            };
            return;
        }
        const sdkResources = {};
        Object.keys(customResources).forEach((lang) => {
            const mergedResource = Object.assign({}, sdkTranslation);
            sdkResources[lang] = {
                translation: Locale.mergeResources(mergedResource, customResources[lang]),
            };
        });
        Locale.__resources__ = sdkResources;
    }
    static mergeResources(mergedResource, customResource) {
        for (const key in customResource) {
            if (typeof mergedResource[key] === 'object' &&
                typeof customResource[key] === 'object') {
                mergedResource[key] = Locale.mergeResources(mergedResource[key], customResource[key]);
            }
            else {
                mergedResource[key] = customResource[key];
            }
        }
        return mergedResource;
    }
    static setup(resources) {
        if (Locale.__i18nInstance__) {
            return Locale.__i18nInstance__;
        }
        Locale.setResources(resources);
        i18n.use(initReactI18next).init({
            fallbackLng: 'en',
            interpolation: {
                escapeValue: false, // not needed for react as it escapes by default
            },
            lng: 'en',
            resources: Locale.getResources(),
        });
        Locale.__i18nInstance__ = i18n;
        return i18n;
    }
    static getInstance() {
        return Locale.__i18nInstance__;
    }
}
Locale.getResources = () => Locale.__resources__;

export { Locale, Locale as default };
