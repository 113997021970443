import { __awaiter } from '../../../../../_virtual/_tslib.js';
import { ProviderEnum } from '@dynamic-labs/sdk-api';
import { useInternalDynamicContext } from '../../../context/DynamicContext/useInternalDynamicContext.js';
import { useEmailVerificationContext } from '../../../context/EmailVerificationContext/EmailVerificationContext.js';
import { useViewContext } from '../../../context/ViewContext/ViewContext.js';
import { useWalletItemActions } from '../useWalletItemActions/useWalletItemActions.js';
import { useConnectWithEmail } from '../useConnectWithEmail/useConnectWithEmail.js';
import { createEmailVerification } from '../../../data/api.js';

const useEmailProvider = ({ emailWalletConnector }) => {
    const { projectSettings, environmentId } = useInternalDynamicContext();
    const { setEmail: setEmailForVerification, setVerificationUUID } = useEmailVerificationContext();
    const { setView } = useViewContext();
    const { handleInstalledExtensionClick } = useWalletItemActions();
    const { connectWithEmail, loading: isEmailProviderLoading } = useConnectWithEmail({
        emailWalletConnector,
        handleConnect: handleInstalledExtensionClick,
    });
    const { providers } = projectSettings || {};
    const isInjectedWalletProviderEnabled = emailWalletConnector !== undefined;
    const isMagicOrBloctoEnabled = isInjectedWalletProviderEnabled &&
        Boolean(providers === null || providers === void 0 ? void 0 : providers.filter((provider) => provider.provider === ProviderEnum.MagicLink ||
            provider.provider === ProviderEnum.Blocto).length);
    const handleEmailOnlyLoginSubmit = (emailInput) => __awaiter(void 0, void 0, void 0, function* () {
        const { email, verificationUUID } = yield createEmailVerification({
            email: emailInput,
            environmentId,
        });
        setView('login-with-email-verification');
        setEmailForVerification(email);
        setVerificationUUID(verificationUUID);
    });
    const handleMagicLinkOrBloctoSubmit = (emailInput) => __awaiter(void 0, void 0, void 0, function* () {
        yield connectWithEmail(emailInput);
    });
    const submitEmail = (emailInput) => __awaiter(void 0, void 0, void 0, function* () {
        if (isMagicOrBloctoEnabled) {
            yield handleMagicLinkOrBloctoSubmit(emailInput);
            return;
        }
        yield handleEmailOnlyLoginSubmit(emailInput);
    });
    return {
        handleEmailOnlyLoginSubmit,
        isEmailProviderLoading,
        submitEmail,
    };
};

export { useEmailProvider };
