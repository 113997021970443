import { __awaiter } from '../../../../../../_virtual/_tslib.js';
import { useState, useEffect } from 'react';
import '@dynamic-labs/sdk-api';
import '../../../../shared/logger.js';
import '@dynamic-labs/iconic';
import '@dynamic-labs/wallet-connector-core';
import 'react/jsx-runtime';
import '../../../../context/ViewContext/ViewContext.js';
import '@dynamic-labs/wallet-book';
import '@dynamic-labs/utils';
import '../../../../utils/constants/colors.js';
import '../../../../shared/utils/classes/storage/localStorage.js';
import 'viem';
import '../../../../shared/consts/index.js';
import '@dynamic-labs/multi-wallet';
import { isWalletConnected } from '../../../../utils/functions/isWalletConnected/isWalletConnected.js';

const useFetchBalance = ({ connector, chain, address, network, }) => {
    const [balance, setBalance] = useState('0.0');
    useEffect(() => {
        const fetchBalance = () => __awaiter(void 0, void 0, void 0, function* () {
            if (!chain ||
                !address ||
                (connector.supportsNetworkSwitching() && !network))
                return;
            const isConnected = yield isWalletConnected({
                address,
                chain,
                connector,
            });
            /* istanbul ignore else */
            if (isConnected) {
                const value = yield connector.getBalance();
                setBalance(value);
            }
        });
        fetchBalance();
    }, [address, chain, connector, network]);
    return [balance];
};

export { useFetchBalance };
