import { jsxs, jsx } from 'react/jsx-runtime';
import '@dynamic-labs/sdk-api';
import '../../../../shared/logger.js';
import { getChainIcon } from '../../../../shared/utils/functions/chain/getChainIcon.js';
import '@dynamic-labs/wallet-connector-core';
import '@dynamic-labs/wallet-book';
import '@dynamic-labs/utils';
import '../../../../utils/constants/colors.js';
import '../../../../shared/utils/classes/storage/localStorage.js';
import 'viem';
import 'react';
import '@dynamic-labs/iconic';
import '../../../../context/ViewContext/ViewContext.js';
import '../../../../shared/consts/index.js';
import { Icon } from '../../../Icon/Icon.js';
import { Typography } from '../../../Typography/Typography.js';

const TokenBalanceCard = ({ chain, currencySymbol, balance, }) => {
    const ChainIcon = getChainIcon(chain);
    return (jsxs("div", { className: 'token-balance-card', children: [jsxs("div", { className: 'token-balance-card__chain', children: [jsx(Icon, { size: 'small', children: jsx(ChainIcon, {}) }), jsx(Typography, { variant: 'body_normal', color: 'primary', children: currencySymbol })] }), Boolean(balance) && (jsxs("div", { className: 'token-balance-card__balance-container', children: [jsx(Typography, { variant: 'body_small', color: 'tertiary', children: "Balance:" }), jsxs("div", { className: 'token-balance-card__balance', children: [jsx(Typography, { variant: 'body_small', color: 'secondary', children: balance }), jsx(Typography, { variant: 'body_small', color: 'tertiary', children: currencySymbol })] })] }))] }));
};

export { TokenBalanceCard };
