import { useEffect } from 'react';
import { useInternalDynamicContext } from '../../../context/DynamicContext/useInternalDynamicContext.js';
import { useViewContext } from '../../../context/ViewContext/ViewContext.js';
import '@dynamic-labs/sdk-api';
import '../../../shared/logger.js';
import '@dynamic-labs/iconic';
import '@dynamic-labs/wallet-connector-core';
import 'react/jsx-runtime';
import '@dynamic-labs/wallet-book';
import '@dynamic-labs/utils';
import '../../constants/colors.js';
import '../../../shared/utils/classes/storage/localStorage.js';
import 'viem';
import '../../../shared/consts/index.js';
import '@dynamic-labs/multi-wallet';
import { isSupportedNetwork } from '../../functions/isSupportedNetwork/isSupportedNetwork.js';
import { isProgrammableNetworkSwitchSupported } from '../../functions/isProgrammableNetworkSwitchSupported/isProgrammableNetworkSwitchSupported.js';

const useConnectedWalletsNetworkValidation = () => {
    const { connectedWallets, selectedWalletConnector, setSelectedWalletConnector, setShowAuthFlow, showAuthFlow, walletsToConnectByChain, isBridgeFlow, } = useInternalDynamicContext();
    const { setView } = useViewContext();
    useEffect(() => {
        if (!isBridgeFlow) {
            return;
        }
        const connectedWalletsWithInvalidNetwork = connectedWallets.filter((connectedWallet) => {
            const isChainInWalletsToConnect = walletsToConnectByChain === null || walletsToConnectByChain === void 0 ? void 0 : walletsToConnectByChain.some((walletToConnectByChain) => walletToConnectByChain.chain === connectedWallet.chain);
            const isSupported = isSupportedNetwork({
                network: connectedWallet.network,
                walletConnector: connectedWallet.connector,
            });
            return !isChainInWalletsToConnect && !isSupported;
        });
        if (connectedWalletsWithInvalidNetwork.length) {
            // If we found wallets that require a change of network
            // then, one by one, in the first one we require a change of network.
            const [connectedWalletWithInvalidNetwork] = connectedWalletsWithInvalidNetwork;
            // Keeps the first wallet connectedWallet in setSelectedWalletConnector
            // This is required because NetworkNotSupported uses walletConnector to display wallet icon, address etc.
            if (connectedWalletWithInvalidNetwork.connector.name !==
                (selectedWalletConnector === null || selectedWalletConnector === void 0 ? void 0 : selectedWalletConnector.name)) {
                setSelectedWalletConnector(connectedWalletWithInvalidNetwork.connector);
            }
            const networkNotSupportedView = isProgrammableNetworkSwitchSupported(connectedWalletWithInvalidNetwork.connector)
                ? 'network-not-supported'
                : 'network-not-supported-manual';
            if (!showAuthFlow) {
                setShowAuthFlow(true);
            }
            setView(networkNotSupportedView);
        }
        // we need it to remove 'showAuthFlow' property. we don't want to react to it,
        // because closing modal on network switch causes it to re-open
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        connectedWallets,
        isBridgeFlow,
        selectedWalletConnector,
        setSelectedWalletConnector,
        setShowAuthFlow,
        setView,
        walletsToConnectByChain,
    ]);
};

export { useConnectedWalletsNetworkValidation };
