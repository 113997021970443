import { __awaiter } from '../../../../../_virtual/_tslib.js';
import { useState, useEffect, useMemo, useCallback } from 'react';
import { isSameAddress } from '@dynamic-labs/wallet-connector-core';
import '@dynamic-labs/sdk-api';
import { logger } from '../../../shared/logger.js';
import '@dynamic-labs/iconic';
import 'react/jsx-runtime';
import '../../../context/ViewContext/ViewContext.js';
import '@dynamic-labs/wallet-book';
import '@dynamic-labs/utils';
import '../../constants/colors.js';
import '../../../shared/utils/classes/storage/localStorage.js';
import 'viem';
import '../../../shared/consts/index.js';
import { useWalletConnectorEvent } from '../useWalletConnectorEvent/useWalletConnectorEvent.js';

const useWalletEventListeners = ({ computeConnectedStateForWallets, handleLogOut, multiWallet, multiWalletWidgetState, primaryWallet, secondaryWallets, selectedWalletConnector, selectedWalletWithAction, setSelectedWalletConnector, setSelectedWalletWithAction, setMultiWalletWidgetState, user, setPrimaryWalletId, authMode, refreshConnectedWallet, }) => {
    const [loadingNetwork, setLoadingNetwork] = useState(true);
    const [network, setNetwork] = useState(undefined);
    useEffect(() => {
        const fetchNetwork = () => __awaiter(void 0, void 0, void 0, function* () {
            try {
                if (!(primaryWallet === null || primaryWallet === void 0 ? void 0 : primaryWallet.connector))
                    return;
                const network = yield primaryWallet.connector.getNetwork();
                setNetwork(network);
            }
            catch (error) {
                /* istanbul ignore next */
                logger.debug(error);
            }
            finally {
                setLoadingNetwork(false);
            }
        });
        fetchNetwork();
    }, [primaryWallet === null || primaryWallet === void 0 ? void 0 : primaryWallet.connector]);
    useWalletConnectorEvent(primaryWallet === null || primaryWallet === void 0 ? void 0 : primaryWallet.connector, 'disconnect', () => {
        logger.debug('primary wallet disconnect');
        handleLogOut();
    });
    useWalletConnectorEvent(primaryWallet === null || primaryWallet === void 0 ? void 0 : primaryWallet.connector, 'chainChange', (_, { chain }) => {
        logger.debug('primary wallet chain change', { chain });
        if (!primaryWallet) {
            return;
        }
        refreshConnectedWallet(primaryWallet.id, primaryWallet.connector);
        // https://linear.app/dynamic-labs/issue/QNTM-84/breaking-change-rename-network-to-primarywalletnetwork
        if (primaryWallet.chain === 'starknet') {
            setNetwork(chain);
        }
        else {
            setNetwork(parseInt(chain));
        }
    });
    useWalletConnectorEvent(primaryWallet === null || primaryWallet === void 0 ? void 0 : primaryWallet.connector, 'accountChange', (_, { accounts }) => __awaiter(void 0, void 0, void 0, function* () {
        logger.debug('primary wallet account change', {
            accounts,
        });
        // change the api here
        if (!primaryWallet)
            return;
        const [newAddress] = accounts;
        const newWallet = secondaryWallets.find((wallet) => isSameAddress(wallet.address, newAddress, wallet.chain));
        if (authMode === 'connect-only') {
            refreshConnectedWallet(primaryWallet.id, primaryWallet.connector);
            return;
        }
        if (isSameAddress(newAddress, primaryWallet.address, primaryWallet.chain)) {
            setMultiWalletWidgetState('idle');
        }
        else if (!multiWallet) {
            yield handleLogOut();
            return;
        }
        else if (multiWalletWidgetState === 'awaiting_account_switch') {
            handleAccountChangeWhenAwaitingAccountSwitch(true, newAddress);
        }
        else if (multiWalletWidgetState === 'idle' && newWallet) {
            setPrimaryWalletId(newWallet.id);
        }
        else if (multiWalletWidgetState === 'idle' && !newWallet) {
            if (user) {
                setMultiWalletWidgetState('detected_new_wallet');
                setSelectedWalletConnector(primaryWallet.connector);
                yield computeConnectedStateForWallets();
                return;
            }
        }
        else {
            logger.error(`Unexpected multiWalletWidgetState (${multiWalletWidgetState}) and onAccountChange combination. Resetting state to idle.`);
            setMultiWalletWidgetState('idle');
        }
        yield computeConnectedStateForWallets();
    }));
    const uniqueNonPrimaryWallets = useMemo(() => {
        const primaryConnector = primaryWallet === null || primaryWallet === void 0 ? void 0 : primaryWallet.connector;
        const secondaryConnectors = secondaryWallets.map(({ connector }) => connector);
        const allWalletConnectors = new Set([
            ...secondaryConnectors,
            primaryConnector,
            selectedWalletConnector,
        ].filter((connector) => Boolean(connector)));
        if (primaryConnector) {
            allWalletConnectors.delete(primaryConnector);
        }
        return Array.from(allWalletConnectors);
    }, [secondaryWallets, selectedWalletConnector, primaryWallet === null || primaryWallet === void 0 ? void 0 : primaryWallet.connector]);
    useWalletConnectorEvent(uniqueNonPrimaryWallets, 'accountChange', () => __awaiter(void 0, void 0, void 0, function* () {
        logger.debug('secondary wallet account change');
        if (authMode === 'connect-only' && secondaryWallets.length) {
            refreshConnectedWallet(secondaryWallets[0].id, secondaryWallets[0].connector);
            return;
        }
        if (multiWalletWidgetState === 'awaiting_account_switch') {
            handleAccountChangeWhenAwaitingAccountSwitch(false, (selectedWalletWithAction === null || selectedWalletWithAction === void 0 ? void 0 : selectedWalletWithAction.wallet.address) || '');
        }
        yield computeConnectedStateForWallets();
    }));
    useWalletConnectorEvent(uniqueNonPrimaryWallets, 'chainChange', () => __awaiter(void 0, void 0, void 0, function* () {
        logger.debug('secondary wallet chain change');
        // We should pass the proper walletId here
        if (!secondaryWallets.length) {
            return;
        }
        refreshConnectedWallet(secondaryWallets[0].id, secondaryWallets[0].connector);
    }));
    useWalletConnectorEvent(uniqueNonPrimaryWallets, 'disconnect', () => {
        logger.debug('secondary wallet disconnect');
        // argent sends a disconnect event when the user adds a new account
        if (authMode === 'connect-only' &&
            secondaryWallets.length &&
            secondaryWallets[0].connector.key === 'argentx') {
            refreshConnectedWallet(secondaryWallets[0].id, secondaryWallets[0].connector);
            return;
        }
        computeConnectedStateForWallets();
    });
    const handleAccountChangeWhenAwaitingAccountSwitch = useCallback((isPrimaryWallet, newAddress) => {
        const linkedSecondaryWallet = secondaryWallets.find((wallet) => isSameAddress(wallet.address, newAddress, wallet.chain));
        // this means the user is linking a new account, so we need proof of ownership...
        if (!linkedSecondaryWallet) {
            setMultiWalletWidgetState('awaiting_signature', undefined, 'linking_new_wallet');
            if (isPrimaryWallet && primaryWallet) {
                setSelectedWalletConnector(primaryWallet.connector);
            }
            return;
        }
        // ...otherwise, the user is switching primary accounts betweeen the same wallet
        setSelectedWalletWithAction(null);
        setPrimaryWalletId(linkedSecondaryWallet.id);
        setMultiWalletWidgetState('idle');
    }, [
        setMultiWalletWidgetState,
        primaryWallet,
        secondaryWallets,
        setSelectedWalletConnector,
        setSelectedWalletWithAction,
        setPrimaryWalletId,
    ]);
    return {
        loadingNetwork,
        network,
        setNetwork,
    };
};

export { useWalletEventListeners };
