import { useEffect } from 'react';
import { useInternalDynamicContext } from '../../../context/DynamicContext/useInternalDynamicContext.js';

//  This is temporary workaround what should be removed after refactoring the useWalletEventListeners.
//  https://linear.app/dynamic-labs/issue/QNTM-74/cleanup-attached-events-to-wallet-connector-in-network-not-supported
//  Currently the events and context properties f.e. `network` are based on primaryWallet
//  which is define after signing the message
const useApplyWalletConnectorNetwork = ({ walletConnector }) => {
    const { network, setNetwork } = useInternalDynamicContext();
    // Updates the contexts 'network' based on the selected wallet connector.
    // It helps ensure that the 'network' is always in sync with the wallet's actual network,
    // Prevents 'network' from being 'undefined'.
    useEffect(() => {
        if (walletConnector) {
            walletConnector.getNetwork().then((walletConnectorNetwork) => {
                if (network !== walletConnectorNetwork) {
                    setNetwork(walletConnectorNetwork);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [network, walletConnector]);
};

export { useApplyWalletConnectorNetwork };
