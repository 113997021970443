import { jsx } from 'react/jsx-runtime';
import { createContext, useState, useMemo, useContext } from 'react';

const CaptchaContext = createContext(undefined);
const CaptchaContextProvider = ({ children, }) => {
    const [captchaToken, setCaptchaToken] = useState(undefined);
    const value = useMemo(() => ({
        captchaToken,
        setCaptchaToken,
    }), [captchaToken]);
    return (jsx(CaptchaContext.Provider, { value: value, children: children }));
};
const useCaptchaContext = () => {
    const context = useContext(CaptchaContext);
    if (context === undefined) {
        throw new Error('usage of useCaptchaContext not wrapped in `CaptchaContextProvider`.');
    }
    return context;
};

export { CaptchaContext, CaptchaContextProvider, useCaptchaContext };
