import { __awaiter } from '../../../../../_virtual/_tslib.js';
import { useReducer, useCallback } from 'react';
import '@dynamic-labs/sdk-api';
import '../../../shared/logger.js';
import '@dynamic-labs/iconic';
import '@dynamic-labs/wallet-connector-core';
import 'react/jsx-runtime';
import '../../../context/ViewContext/ViewContext.js';
import '@dynamic-labs/wallet-book';
import '@dynamic-labs/utils';
import '../../constants/colors.js';
import '../../../shared/utils/classes/storage/localStorage.js';
import 'viem';
import '../../../shared/consts/index.js';
import '@dynamic-labs/multi-wallet';
import { isWalletConnected } from '../../functions/isWalletConnected/isWalletConnected.js';

const connectedWalletStateReducer = (state, connectedWalletsMap) => {
    const isConnectedWalletsMapEmpty = Object.values(connectedWalletsMap).length === 0;
    if (isConnectedWalletsMapEmpty && state.didLoad === false) {
        return state;
    }
    return Object.assign(Object.assign({}, state), { connectedWalletsMap, didLoad: true });
};
const useWalletsConnectionState = (linkedWallets) => {
    const [state, setState] = useReducer(connectedWalletStateReducer, {
        connectedWalletsMap: {},
        didLoad: false,
    });
    const computeConnectedStateForWallets = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        const connectedWalletsMap = {};
        yield Promise.all(linkedWallets.map((wallet) => __awaiter(void 0, void 0, void 0, function* () {
            connectedWalletsMap[wallet.id] = yield isWalletConnected(wallet);
        })));
        setState(connectedWalletsMap);
    }), [linkedWallets, setState]);
    return {
        computeConnectedStateForWallets,
        connectedWalletMap: state.connectedWalletsMap,
        didConnectedStateLoad: state.didLoad,
    };
};

export { useWalletsConnectionState };
