"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTimeoutConfig = exports.isBlockHeightStrategy = exports.BlockHeightStrategyClass = exports.TimeStrategyClass = exports.SequenceType = exports.TransactionInstructionWithSigners = void 0;
var TransactionInstructionWithSigners = /** @class */ (function () {
    function TransactionInstructionWithSigners(transactionInstruction, signers) {
        if (signers === void 0) { signers = []; }
        this.transactionInstruction = transactionInstruction;
        this.signers = signers;
    }
    return TransactionInstructionWithSigners;
}());
exports.TransactionInstructionWithSigners = TransactionInstructionWithSigners;
var SequenceType;
(function (SequenceType) {
    SequenceType[SequenceType["Sequential"] = 0] = "Sequential";
    SequenceType[SequenceType["Parallel"] = 1] = "Parallel";
    SequenceType[SequenceType["StopOnFailure"] = 2] = "StopOnFailure";
})(SequenceType = exports.SequenceType || (exports.SequenceType = {}));
var TimeStrategyClass = /** @class */ (function () {
    function TimeStrategyClass(_a) {
        var _b = _a.timeout, timeout = _b === void 0 ? 90 : _b, _c = _a.getSignatureStatusesPoolIntervalMs, getSignatureStatusesPoolIntervalMs = _c === void 0 ? 5000 : _c;
        this.timeout = timeout;
        this.getSignatureStatusesPoolIntervalMs = getSignatureStatusesPoolIntervalMs;
    }
    return TimeStrategyClass;
}());
exports.TimeStrategyClass = TimeStrategyClass;
var BlockHeightStrategyClass = /** @class */ (function () {
    function BlockHeightStrategyClass(_a) {
        var _b = _a.startBlockCheckAfterSecs, startBlockCheckAfterSecs = _b === void 0 ? 90 : _b, block = _a.block, _c = _a.getSignatureStatusesPoolIntervalMs, getSignatureStatusesPoolIntervalMs = _c === void 0 ? 5000 : _c;
        this.startBlockCheckAfterSecs = startBlockCheckAfterSecs;
        this.block = block;
        this.getSignatureStatusesPoolIntervalMs = getSignatureStatusesPoolIntervalMs;
    }
    return BlockHeightStrategyClass;
}());
exports.BlockHeightStrategyClass = BlockHeightStrategyClass;
var isBlockHeightStrategy = function (timeoutStrategy) {
    return 'block' in timeoutStrategy;
};
exports.isBlockHeightStrategy = isBlockHeightStrategy;
var getTimeoutConfig = function (timeoutStrategy) {
    var isBhStrategy = (0, exports.isBlockHeightStrategy)(timeoutStrategy);
    var timeoutConfig = !isBhStrategy
        ? new TimeStrategyClass(__assign({}, timeoutStrategy))
        : new BlockHeightStrategyClass(__assign({}, timeoutStrategy));
    return timeoutConfig;
};
exports.getTimeoutConfig = getTimeoutConfig;
