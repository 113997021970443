/**
 * This code was GENERATED using the solita package.
 * Please DO NOT EDIT THIS FILE, instead rerun solita to update it or write a wrapper to add functionality.
 *
 * See: https://github.com/metaplex-foundation/solita
 */

import * as beet from '@metaplex-foundation/beet';

import { ApplicationDataEventV1, applicationDataEventV1Beet } from './ApplicationDataEventV1';
/**
 * This type is used to derive the {@link ApplicationDataEvent} type as well as the de/serializer.
 * However don't refer to it in your code but use the {@link ApplicationDataEvent} type instead.
 *
 * @category userTypes
 * @category enums
 * @category generated
 * @private
 */
export type ApplicationDataEventRecord = {
    V1: { fields: [ApplicationDataEventV1] };
};

/**
 * Union type respresenting the ApplicationDataEvent data enum defined in Rust.
 *
 * NOTE: that it includes a `__kind` property which allows to narrow types in
 * switch/if statements.
 * Additionally `isApplicationDataEvent*` type guards are exposed below to narrow to a specific variant.
 *
 * @category userTypes
 * @category enums
 * @category generated
 */
export type ApplicationDataEvent = beet.DataEnumKeyAsKind<ApplicationDataEventRecord>;

export const isApplicationDataEventV1 = (x: ApplicationDataEvent): x is ApplicationDataEvent & { __kind: 'V1' } =>
    x.__kind === 'V1';

/**
 * @category userTypes
 * @category generated
 */
export const applicationDataEventBeet = beet.dataEnum<ApplicationDataEventRecord>([
    [
        'V1',
        new beet.FixableBeetArgsStruct<ApplicationDataEventRecord['V1']>(
            [['fields', beet.tuple([applicationDataEventV1Beet])]],
            'ApplicationDataEventRecord["V1"]'
        ),
    ],
]) as beet.FixableBeet<ApplicationDataEvent>;
