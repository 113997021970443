import { useInternalDynamicContext } from '../../../context/DynamicContext/useInternalDynamicContext.js';
import '@dynamic-labs/sdk-api';
import '../../../shared/logger.js';
import '@dynamic-labs/iconic';
import '@dynamic-labs/wallet-connector-core';
import 'react/jsx-runtime';
import '../../../context/ViewContext/ViewContext.js';
import 'react';
import '@dynamic-labs/wallet-book';
import { isSSR } from '../../../shared/utils/functions/isSSR/isSSR.js';
import '@dynamic-labs/utils';
import '../../constants/colors.js';
import '../../../shared/utils/classes/storage/localStorage.js';
import 'viem';
import '../../../shared/consts/index.js';

const isMultiWalletEnabledInURL = () => {
    const flag = 'multiwallet.widget';
    const searchParams = new URLSearchParams(!isSSR() ? window.location.search : '');
    // by default, enable the widget
    if (!searchParams.has(flag)) {
        return true;
    }
    return searchParams.get(flag) === 'true';
};
const useMultiWalletFlag = () => {
    const { multiWallet: isMultiWalletEnabled } = useInternalDynamicContext();
    return isMultiWalletEnabled && isMultiWalletEnabledInURL();
};

export { isMultiWalletEnabledInURL, useMultiWalletFlag };
