import { jsxs, Fragment, jsx } from 'react/jsx-runtime';
import { useTranslation } from 'react-i18next';
import { WalletConnectIcon } from '@dynamic-labs/iconic';

const useDynamicLayoutData = ({ view, authMode, }) => {
    const { t } = useTranslation();
    if (view === 'no-access') {
        const headerData = {
            heading: t('dyn_no_access.title'),
        };
        return { headerData };
    }
    const helpHeaderMap = {
        'login-with-email-or-wallet-full-wallet-list': {
            heading: t('dyn_login.helper.all_wallet_list'),
        },
        'login-with-wallet-only': {
            heading: t('dyn_login.helper.wallet_only'),
        },
        'passkey-intro': {
            heading: t('dyn_passkey_intro.helper.title'),
        },
        'pending-connect': {
            heading: t('dyn_login.helper.pending_connect.title'),
        },
        'pending-signature': {
            heading: t('dyn_login.helper.pending_signature.title'),
        },
        'pending-signature-without-back-button': {
            heading: t('dyn_login.helper.pending_signature_without_back_button.title'),
        },
        'qr-code': {
            heading: t('dyn_login.helper.qr_code.title'),
        },
        'wallet-connect-mobile-wallets-list': {
            heading: t('dyn_wallet_conect.mobile.wallet_list.helper'),
        },
        'wallet-list': {
            heading: t('dyn_wallet_list.helper'),
        },
    };
    const headerMap = {
        'account-exists': { heading: t('dyn_account_exists.title') },
        'chainalysis-blocked-wallet': {
            heading: t('dyn_chainalysis_blocked_wallet.title'),
        },
        'login-with-email-or-wallet': { heading: t('dyn_login.title.all') },
        'login-with-email-or-wallet-full-wallet-list': {
            heading: t('dyn_login.title.all_wallet_list'),
        },
        'login-with-email-verification': {
            heading: 'Confirm verification code',
        },
        'login-with-wallet-only': {
            heading: t('dyn_login.title.wallet_only'),
        },
        'multi-wallet-wallet-list': {
            heading: authMode === 'connect-only'
                ? t('dyn_wallet_list.title.connect')
                : t('dyn_wallet_list.title.link'),
        },
        'network-not-supported': {
            heading: 'Update Network',
        },
        'pending-connect': {
            heading: t('dyn_login.connect_wallet.title'),
        },
        'pending-signature': {
            heading: t('dyn_login.sign_wallet.title'),
        },
        'qr-code': {
            heading: t('dyn_login.qr_code.title'),
        },
        'verify-email': {
            heading: 'Confirm verification code',
        },
        'wallet-connect-mobile-wallets-list': {
            heading: (jsxs(Fragment, { children: [jsx(WalletConnectIcon, { style: { height: '1.5rem', width: '1.5rem' } }), ' ', t('dyn_wallet_conect.mobile.wallet_list.title')] })),
        },
        'wallet-group': {
            heading: t('dyn_login.wallet_group.title'),
        },
        'wallet-list': {
            heading: t('dyn_wallet_list.title.select'),
        },
        'wallet-sign': { heading: t('dyn_wallet_transfer.sign.title') },
        'wallet-used': {
            heading: 'Transfer this wallet?',
        },
    };
    const helpHeaderData = helpHeaderMap[view];
    const headerData = headerMap[view];
    return {
        headerData,
        helpHeaderData,
    };
};

export { useDynamicLayoutData };
