import { TurnkeyWalletConnector } from './lib/TurnkeyWalletConnector/TurnkeyWalletConnector.js';
export { TurnkeyWalletConnector } from './lib/TurnkeyWalletConnector/TurnkeyWalletConnector.js';

const TurnkeyWalletConnectors = (props) => {
    var _a, _b;
    if ((_b = (_a = props.apiProviders) === null || _a === void 0 ? void 0 : _a.turnkey) === null || _b === void 0 ? void 0 : _b.enabledAt) {
        return [TurnkeyWalletConnector];
    }
    return [];
};

export { TurnkeyWalletConnectors };
