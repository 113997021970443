import { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { SdkViewType, SdkViewSectionType } from '@dynamic-labs/sdk-api';
import { useInternalDynamicContext } from '../../context/DynamicContext/useInternalDynamicContext.js';
import { useSocialRedirectContext } from '../../context/SocialRedirectContext/SocialRedirectContext.js';
import '../../shared/logger.js';
import '@dynamic-labs/iconic';
import '@dynamic-labs/wallet-connector-core';
import 'react/jsx-runtime';
import '../../context/ViewContext/ViewContext.js';
import '@dynamic-labs/wallet-book';
import '@dynamic-labs/utils';
import '../../utils/constants/colors.js';
import '../../shared/utils/classes/storage/localStorage.js';
import 'viem';
import '../../shared/consts/index.js';
import '@dynamic-labs/multi-wallet';
import { isEmailProviderEnabled } from '../../utils/functions/isEmailProviderEnabled/isEmailProviderEnabled.js';
import { getEnabledProviders } from '../../utils/functions/getEnabledProviders/index.js';

/* eslint-disable multiline-comment-style */
const NUMBER_OF_WALLETS = 3;
const useLoginView = () => {
    var _a, _b, _c, _d;
    const { projectSettings, authorizationViewDisplayOrder, loginWithEmail, wallets, walletsFilter, } = useInternalDynamicContext();
    const { enabledSocialProviders } = useSocialRedirectContext();
    /** Takes from wallets only those that can be selected in the wallet list by the user.
     * Should filter out embedded wallets
     * WHY: when we decide whether to show wallets or not, we need to know how many wallets
     * will actually be rendered. It would be especially bad to only have for ex "turnkey" in
     * wallets and then display an empty wallet list (since turnkey doesn't render)
     */
    const selectableWallets = (walletsFilter ? walletsFilter(wallets) : wallets).filter(({ key }) => key !== 'turnkey');
    const { t } = useTranslation();
    const hasEnabledProviders = Boolean(getEnabledProviders(projectSettings === null || projectSettings === void 0 ? void 0 : projectSettings.providers).length);
    const isWalletOnly = useMemo(() => {
        var _a, _b, _c, _d;
        if (!projectSettings) {
            return false;
        }
        // toolkit functionality overrides whatever is defined
        if (!loginWithEmail) {
            return true;
        }
        const loginView = (_b = (_a = projectSettings === null || projectSettings === void 0 ? void 0 : projectSettings.sdk) === null || _a === void 0 ? void 0 : _a.views) === null || _b === void 0 ? void 0 : _b.find((view) => view.type === SdkViewType.Login);
        // backwards compatibility for those that didn't set up login view sessions
        if (!((_c = loginView === null || loginView === void 0 ? void 0 : loginView.sections) === null || _c === void 0 ? void 0 : _c.length)) {
            return (!((_d = projectSettings === null || projectSettings === void 0 ? void 0 : projectSettings.providers) === null || _d === void 0 ? void 0 : _d.length) ||
                !isEmailProviderEnabled(projectSettings === null || projectSettings === void 0 ? void 0 : projectSettings.providers));
        }
        return (loginView.sections.length === 1 && loginView.sections[0].type === 'wallet');
    }, [projectSettings, loginWithEmail]);
    /**
     * build email and social view sections from project settings design.modal props - backwards compatibility
     * @returns SdkViewSection[]
     */
    const getEmailViewSectionDefinition = useCallback(() => {
        var _a, _b;
        const loginViewSections = [
            {
                type: SdkViewSectionType.Email,
            },
        ];
        if (enabledSocialProviders === null || enabledSocialProviders === void 0 ? void 0 : enabledSocialProviders.length) {
            const socialSection = {
                numOfItemsToDisplay: 5,
                type: SdkViewSectionType.Social,
            };
            loginViewSections.push(socialSection);
            if ((_a = projectSettings === null || projectSettings === void 0 ? void 0 : projectSettings.design.modal) === null || _a === void 0 ? void 0 : _a.splitEmailAndSocial) {
                loginViewSections.splice(1, 0, {
                    label: t('dyn_login.separators.default'),
                    type: SdkViewSectionType.Separator,
                });
                // eslint-disable-next-line prefer-destructuring
                socialSection.defaultItem = enabledSocialProviders[0];
            }
        }
        if ((_b = projectSettings === null || projectSettings === void 0 ? void 0 : projectSettings.design.modal) === null || _b === void 0 ? void 0 : _b.socialAboveEmail) {
            loginViewSections.reverse();
        }
        return loginViewSections;
    }, [
        enabledSocialProviders,
        (_a = projectSettings === null || projectSettings === void 0 ? void 0 : projectSettings.design.modal) === null || _a === void 0 ? void 0 : _a.socialAboveEmail,
        (_b = projectSettings === null || projectSettings === void 0 ? void 0 : projectSettings.design.modal) === null || _b === void 0 ? void 0 : _b.splitEmailAndSocial,
        t,
    ]);
    // TODO: https://linear.app/dynamic-labs/issue/GVTY-212/rethink-or-implement-toolkit-template-for-email-wallet-order
    /**
     * remove wallet view section from projectSettings?.sdk?.views if no wallet defined
     * @param sections
     * @returns
     */
    const filterLoginViewSections = useCallback((sections) => {
        if (selectableWallets === null || selectableWallets === void 0 ? void 0 : selectableWallets.length) {
            return sections;
        }
        return sections.filter((section) => section.type !== 'wallet');
    }, [selectableWallets === null || selectableWallets === void 0 ? void 0 : selectableWallets.length]);
    const addSeparator = useCallback((index) => {
        // if not the first index, no separator is needed
        // if the first index but does not have wallets or providers,
        // it'll show only one view section - no separator is needed
        if (index !== 0 || !(selectableWallets === null || selectableWallets === void 0 ? void 0 : selectableWallets.length) || !hasEnabledProviders) {
            return [];
        }
        return [
            {
                label: t('dyn_login.separators.default'),
                type: SdkViewSectionType.Separator,
            },
        ];
    }, [hasEnabledProviders, selectableWallets === null || selectableWallets === void 0 ? void 0 : selectableWallets.length, t]);
    /**
     * build login view sections array from project settings design.modal props - backwards compatibility
     * @returns SdkViewSection[]
     */
    const buildLoginSections = useCallback(() => {
        let loginViewSections = [];
        authorizationViewDisplayOrder === null || authorizationViewDisplayOrder === void 0 ? void 0 : authorizationViewDisplayOrder.forEach((loginItem, index) => {
            var _a, _b;
            if (loginItem === 'email' &&
                (projectSettings === null || projectSettings === void 0 ? void 0 : projectSettings.providers) &&
                isEmailProviderEnabled(projectSettings === null || projectSettings === void 0 ? void 0 : projectSettings.providers)) {
                loginViewSections = loginViewSections.concat(getEmailViewSectionDefinition(), 
                // add separator if wallets defined
                addSeparator(index));
            }
            if (loginItem === 'wallet' && (selectableWallets === null || selectableWallets === void 0 ? void 0 : selectableWallets.length)) {
                // if showWalletsButton is true, it should show "Connec with Wallet" button
                const numOfItemsToDisplay = ((_b = (_a = projectSettings === null || projectSettings === void 0 ? void 0 : projectSettings.design) === null || _a === void 0 ? void 0 : _a.modal) === null || _b === void 0 ? void 0 : _b.showWalletsButton)
                    ? 0
                    : NUMBER_OF_WALLETS;
                loginViewSections = loginViewSections.concat([
                    {
                        numOfItemsToDisplay,
                        type: SdkViewSectionType.Wallet,
                    },
                ], 
                // add separator if email providers defined
                addSeparator(index));
            }
        });
        return loginViewSections;
    }, [
        addSeparator,
        authorizationViewDisplayOrder,
        getEmailViewSectionDefinition,
        (_d = (_c = projectSettings === null || projectSettings === void 0 ? void 0 : projectSettings.design) === null || _c === void 0 ? void 0 : _c.modal) === null || _d === void 0 ? void 0 : _d.showWalletsButton,
        projectSettings === null || projectSettings === void 0 ? void 0 : projectSettings.providers,
        selectableWallets === null || selectableWallets === void 0 ? void 0 : selectableWallets.length,
    ]);
    /**
     * returns the login view sections to be displayed in order from projectSettings?.sdk?.views
     * and has backwards compatibility with project settings design.modal props
     */
    const logingViewSections = useMemo(() => {
        var _a, _b, _c, _d, _e;
        const loginView = (_b = (_a = projectSettings === null || projectSettings === void 0 ? void 0 : projectSettings.sdk) === null || _a === void 0 ? void 0 : _a.views) === null || _b === void 0 ? void 0 : _b.find((view) => view.type === SdkViewType.Login);
        if ((_c = loginView === null || loginView === void 0 ? void 0 : loginView.sections) === null || _c === void 0 ? void 0 : _c.length) {
            return filterLoginViewSections(loginView === null || loginView === void 0 ? void 0 : loginView.sections);
        }
        if ((_e = (_d = projectSettings === null || projectSettings === void 0 ? void 0 : projectSettings.design) === null || _d === void 0 ? void 0 : _d.modal) === null || _e === void 0 ? void 0 : _e.emailOnly) {
            return getEmailViewSectionDefinition();
        }
        return buildLoginSections();
    }, [
        projectSettings,
        getEmailViewSectionDefinition,
        filterLoginViewSections,
        buildLoginSections,
    ]);
    return {
        isWalletOnly,
        logingViewSections,
    };
};

export { NUMBER_OF_WALLETS, useLoginView };
