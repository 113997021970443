/**
 * This code was GENERATED using the solita package.
 * Please DO NOT EDIT THIS FILE, instead rerun solita to update it or write a wrapper to add functionality.
 *
 * See: https://github.com/metaplex-foundation/solita
 */

import * as beet from '@metaplex-foundation/beet';

import { ApplicationDataEvent, applicationDataEventBeet } from './ApplicationDataEvent';
import { ChangeLogEvent, changeLogEventBeet } from './ChangeLogEvent';
/**
 * This type is used to derive the {@link AccountCompressionEvent} type as well as the de/serializer.
 * However don't refer to it in your code but use the {@link AccountCompressionEvent} type instead.
 *
 * @category userTypes
 * @category enums
 * @category generated
 * @private
 */
export type AccountCompressionEventRecord = {
    ChangeLog: { fields: [ChangeLogEvent] };
    ApplicationData: { fields: [ApplicationDataEvent] };
};

/**
 * Union type respresenting the AccountCompressionEvent data enum defined in Rust.
 *
 * NOTE: that it includes a `__kind` property which allows to narrow types in
 * switch/if statements.
 * Additionally `isAccountCompressionEvent*` type guards are exposed below to narrow to a specific variant.
 *
 * @category userTypes
 * @category enums
 * @category generated
 */
export type AccountCompressionEvent = beet.DataEnumKeyAsKind<AccountCompressionEventRecord>;

export const isAccountCompressionEventChangeLog = (
    x: AccountCompressionEvent
): x is AccountCompressionEvent & { __kind: 'ChangeLog' } => x.__kind === 'ChangeLog';
export const isAccountCompressionEventApplicationData = (
    x: AccountCompressionEvent
): x is AccountCompressionEvent & { __kind: 'ApplicationData' } => x.__kind === 'ApplicationData';

/**
 * @category userTypes
 * @category generated
 */
export const accountCompressionEventBeet = beet.dataEnum<AccountCompressionEventRecord>([
    [
        'ChangeLog',
        new beet.FixableBeetArgsStruct<AccountCompressionEventRecord['ChangeLog']>(
            [['fields', beet.tuple([changeLogEventBeet])]],
            'AccountCompressionEventRecord["ChangeLog"]'
        ),
    ],
    [
        'ApplicationData',
        new beet.FixableBeetArgsStruct<AccountCompressionEventRecord['ApplicationData']>(
            [['fields', beet.tuple([applicationDataEventBeet])]],
            'AccountCompressionEventRecord["ApplicationData"]'
        ),
    ],
]) as beet.FixableBeet<AccountCompressionEvent>;
